// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-firm-js": () => import("./../../../src/pages/firm.js" /* webpackChunkName: "component---src-pages-firm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */),
  "component---src-templates-employee-page-js": () => import("./../../../src/templates/employee-page.js" /* webpackChunkName: "component---src-templates-employee-page-js" */)
}

